<template>
  <v-main>
    <section id="hero">
      <v-row
          align="center"
          align-content="center"
          class="white--text mx-auto"
          justify="center"
          style="height: 100vh"
      >

        <v-col class="white--text text-center" cols="12" tag="h1">

          <vue-typer text="Plamen Panayotov" :repeat="0" pre-type-delay='700'>
          </vue-typer>

          <br />
          <vue-typer text="Web Developer" :repeat="0" pre-type-delay='1700'>
          </vue-typer>
        </v-col>

        <v-col class="text-center" cols="12">

          <v-btn
              v-for="({ icon, social }, i) in socials"
              :key="i"
              v-bind:href="social"
              class="mx-4 white--text"
              icon
              target="_blank"

          >
            <v-icon size="36px">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-col>

      </v-row>
    </section>

  </v-main>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      show: false,
      socials: [
        {
          icon: 'mdi-facebook',
          social: 'https://www.facebook.com/plamen.panayotov.946',
          type: 'OPEN_FB'
        },
        {
          icon: 'mdi-linkedin',
          social: 'https://www.linkedin.com/in/plamen-panayotov-a52859131/',
          type: 'OPEN_LI'
        },
      ],
    };
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        setTimeout(() => {
          this.show = true
        }, 1600)
      }
    }
  }
};
</script>

<style>
#hero {
  background: #000000;
  height: 100vh;
}
.vue-typer {
  font-size: 1.875rem;
}

@media (min-width: 640px) {
  .vue-typer {
    font-size: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .vue-typer {
    font-size: 3.125rem;
  }
}

.vue-typer .custom.char.typed {
  color: #fff;
}
.vue-typer .custom.char.selected {
  color: #fff;
  background-color: transparent;
  text-decoration: line-through;
}

.vue-typer .custom.caret {
  display: none;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.name {
  display: none;
}
</style>